export const ADMIN_COOKIE_NAME = "lockdown_with_larry";
export const VISITOR_COOKIE_NAME = "visitor_lwl";

export const DEV_MODE = false;
export const FS_COLLECTION_CHALLENGES = "challenges";
export const FS_COLLECTION_ANSWERS = "answers";

export const EVENT_NEW_COOKIE_CREATED = "new_cookie_created";
export const EVENT_QUESTION_ANSWERED = "question_answered";
export const EVENT_QUESTION_WRONG = "question_answered_wrong";
export const EVENT_QUESTION_RIGHT = "question_answered_right";
export const EVENT_SHARE_BUTTON_CLICKED = "share_button_clicked";
export const EVENT_TIP_BUTTON_CLICKED = "tip_button_clicked";
export const SHARE_URL = "www.lockdownwithlarry.com";
export const SHARE_MESSAGE =
    "Bored at home? Play Lockdown with Larry! 30 days. 30 challenges. One pet hedgehog. Lockdown with larry is a virtual advrnture for those in self-isolation. Solve one challenge each day. It won’t be easy, but it’ll be worth it!";