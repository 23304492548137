import Cookies from "universal-cookie";
import { ADMIN_COOKIE_NAME, VISITOR_COOKIE_NAME, DEV_MODE } from "./constants";
import history from "./history";

const COOKIE_OPTIONS = {
  path: "/",
  maxAge: 3600 * 2 * 30 * 24,
  expires: new Date("2020-06-30"),
};

class CookiesManager {
  constructor() {
    this.cookies = new Cookies();
  }

  getVisitorCookie() {
    return this.cookies.get(VISITOR_COOKIE_NAME, COOKIE_OPTIONS);
  }

  updateVisitorCookie(data) {
    this.cookies.set(VISITOR_COOKIE_NAME, data, COOKIE_OPTIONS);
  }

  createVisitorCookie() {
    try {
      const now = new Date();
      const data = {
        creationTime: now,
        lastSignInTime: now,
        challengeNumber: 1,
        answered: []
      };

      this.cookies.set(VISITOR_COOKIE_NAME, data, COOKIE_OPTIONS);
    } catch (error) {
      console.error("Could not create cookie: ", error);
    }
  }

  deleteVisitorCookie() {
    try {
      this.cookies.remove(VISITOR_COOKIE_NAME);
    } catch (error) {
      console.error(error);
    }
  }

  /**
 * TODO: set options
 * options (object): Support all the cookie options from RFC 6265

    path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
    expires (Date): absolute expiration date for the cookie
    maxAge (number): relative max age of the cookie from when the client receives it in seconds
    domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
    secure (boolean): Is only accessible through HTTPS?
    httpOnly (boolean): Is only the server can access the cookie?
    sameSite (boolean|none|lax|strict): Strict or Lax enforcement
 */

  getUserCookie() {
    const adminCookie = this.cookies.get(ADMIN_COOKIE_NAME);

    if (!DEV_MODE) {
      if (!adminCookie || adminCookie === undefined) {
        console.error("No admin cookie found, redirecting to login!");
        history.push("/admin/login");
        return;
      }
    }
    return adminCookie;
  }

  createUserCookie(fbUserObject) {
    if (!fbUserObject || fbUserObject === undefined)
      throw new Error("No Firebase User object included");
    try {
      const data = {
        uid: fbUserObject.uid,
        email: fbUserObject.email ? fbUserObject.email : "no_email",
        creationTime: fbUserObject.metadata
          ? fbUserObject.metadata.creationTime
            ? fbUserObject.metadata.creationTime
            : "no_date"
          : "no_date",
        lastSignInTime: fbUserObject.metadata
          ? fbUserObject.metadata.lastSignInTime
            ? fbUserObject.metadata.lastSignInTime
            : "no_date"
          : "no_date",
        //TODO: check if this really is a token
        token: fbUserObject.xa ? fbUserObject.xa : "no_token",
        refreshToken: fbUserObject.refreshToken
          ? fbUserObject.refreshToken
          : "no_refresh_token",
      };

      //TODO: can have change and remove listeners
      this.cookies.set(ADMIN_COOKIE_NAME, data, { path: "/" });
    } catch (error) {
      console.error("Could not create cookie: ", error);
    }
  }

  deleteUserCookie() {
    try {
      this.cookies.remove(ADMIN_COOKIE_NAME);
    } catch (error) {
      console.error(error);
    }
  }
}

export default CookiesManager;
