import React from "react";

import { firestore, storage } from "../util/firebase_functions";
import { FS_COLLECTION_CHALLENGES, FS_COLLECTION_ANSWERS } from "../util/constants";
import history from "../util/history";
import ChallengeForm from "./challenge_form";

class AddChallenge extends React.Component {
  render() {
    return (
      <>
        <h2>Nieuwe Challenge</h2>
        <ChallengeForm buttonText="Voeg Toe!" onSubmit={this.onSubmit} />;
      </>
    );
  }

  onSubmit = async (newChallengeObject, pictureFile) => {
    try {
      let pictureRef = "";
      if (pictureFile) pictureRef = await this._uploadImgToStorage(pictureFile);

      const answerObject = { day: newChallengeObject.day, answer: newChallengeObject.answer };

      let objWithPicture = { ...newChallengeObject, picture: pictureRef };

      const snapshot = await firestore
        .collection(FS_COLLECTION_CHALLENGES)
        .add(objWithPicture);
      this.props.onCreated({ ...objWithPicture, id: snapshot.id });

      delete objWithPicture.answer;
      const answerSnapshot = await firestore
        .collection(FS_COLLECTION_ANSWERS)
        .add(answerObject);
      console.log("Answer snapshot:", answerSnapshot);

      if (snapshot) history.push("/admin");
    } catch (error) {
      console.error("Error while adding to firestore: ", error);
      alert(
        `Error! Error while adding to firestore: ${error}. Neem een screenshot en stuur door naar Arno!`
      );
    }
  };

  _uploadImgToStorage = async file => {
    try {
      const storageRef = storage.ref();
      let newImageRef = storageRef.child(file.name);
      const snapshot = await newImageRef.put(file);
      return `gs://${snapshot.ref.location.bucket}/${snapshot.ref.location.path}`;
    } catch (error) {
      console.error("Could not upload image: ", error);
      alert(
        `Error! Could not upload image: ${error}. Neem een screenshot en stuur door naar Arno!`
      );
    }
  };
}

export default AddChallenge;
