import React from "react";
import { storage } from "../util/firebase_functions";
import { Card, Image, Icon, Segment } from "semantic-ui-react";
import MyLoader from "./my_loader";
import notFound from "../images/not_found.png";

const ELLIPSIS_MAX = 150;

class ChallengeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadURL: undefined,
      isDownloading: false
    };
  }

  componentDidMount() {
    if (!this.state.isDownloading && !this.state.downloadURL)
      this._downloadChallengeImage(this.props.challenge.picture);
  }

  render() {
    const challenge = this.props.challenge;
    const isDownloading = this.state.isDownloading;
    if (!challenge)
      return (
        <Card>
          <Card.Content>
            <MyLoader />
          </Card.Content>
        </Card>
      );

    // TODO: Challenge active / not
    return (
      <Card>
        <Segment
          className="image-segment"
          loading={isDownloading ? true : false}
          padded={false}
        >
          <div id="circle"></div>
          <p id="day-number">{challenge.day ? challenge.day : -1}</p>
          {this._showImage()}
        </Segment>
        <Card.Content>
          <Card.Header>
            {challenge.title ? challenge.title : "Geen titel"}
          </Card.Header>
          <Card.Meta>
            {challenge.subtitle ? challenge.subtitle : "Geen subtitel"}
          </Card.Meta>
          <Card.Description>{this._getDescription()}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Icon
            className="edit-icon"
            name="edit outline"
            onClick={() => this.props.onEdit(challenge)}
          />
          <Icon
            className="delete-icon"
            name="trash alternate outline"
            onClick={() => this.props.onDelete(challenge)}
          />
        </Card.Content>
      </Card>
    );
  }

  _getDescription = () => {
    const challenge = this.props.challenge;
    if (challenge.description) {
      if (challenge.description.length > ELLIPSIS_MAX)
        return this._getTextEllipsis(challenge.description);
      return challenge.description;
    } else {
      return "Geen beschrijving!";
    }
  };

  _getTextEllipsis = text => {
    return text.substring(0, ELLIPSIS_MAX - 3) + "...";
  };

  _showImage = () => {
    if (this.state.downloadURL) {
      return <Image className="challenge-image" src={this.state.downloadURL} />;
    } else if (!this.state.isDownloading) {
      return <Image className="challenge-image" src={notFound} />;
    }
  };

  _downloadChallengeImage = async filename => {
    try {
      console.log("Downloading!");
      this.setState({ isDownloading: true });
      const URL = await storage.refFromURL(filename).getDownloadURL();
      this.setState({ downloadURL: URL, isDownloading: false });
    } catch (error) {
      this.setState({ downloadURL: notFound, isDownloading: false });
    }
  };
}

export default ChallengeCard;
