import React from "react";
import { firestore, storage } from "../util/firebase_functions";
import { FS_COLLECTION_CHALLENGES, FS_COLLECTION_ANSWERS } from "../util/constants";
import { Loader, Card } from "semantic-ui-react";
import ChallengeCard from "./challenge_card";

class AllChallenges extends React.Component {
  componentDidMount() {
    const challenges = this.props.challenges;
    if (!challenges || challenges.length === 0 || challenges === [])
      this._loadChallenges();
  }

  render() {
    return (
      <>
        <h2>Alle Challenges</h2>
        {this._renderChallengesCards()}
      </>
    );
  }

  _renderChallengesCards = () => {
    let challenges = this.props.challenges;
    challenges = challenges.sort((a, b) => a.day - b.day);
    if (!challenges || challenges.length === 0) return <Loader />;

    return (
      <Card.Group itemsPerRow={4}>
        {challenges.map(challenge => (
          <ChallengeCard
            key={challenge.id}
            challenge={challenge}
            onDelete={challenge => this._deleteChallenge(challenge)}
            onEdit={challenge => this.props.onEditChallenge(challenge)}
          />
        ))}
      </Card.Group>
    );
  };

  _deleteChallenge = async challenge => {
    try {
      if (challenge.picture) {
        await storage.refFromURL(challenge.picture).delete();
      }
      await firestore
        .collection(FS_COLLECTION_CHALLENGES)
        .doc(challenge.id)
        .delete();

      const toBeDeletedAnswer = await firestore.collection(FS_COLLECTION_ANSWERS).where("day", "==", Number(challenge.day))
        .limit(1)
        .get();

      console.log("toBeDeletedAnswer", toBeDeletedAnswer);
      await firestore.collection(FS_COLLECTION_ANSWERS).doc(toBeDeletedAnswer.docs[0].id).delete();

      this.props.onDeleteChallenge(challenge);
    } catch (error) {
      console.error("Could not remove:", error);
    }
  };

  _loadChallenges = async () => {
    try {
      const snapshot = await firestore
        .collection(FS_COLLECTION_CHALLENGES)
        .get();

      const answersSnapshot = await firestore.collection(
        FS_COLLECTION_ANSWERS).get();
      const answers = answersSnapshot.docs.map(doc => {
        return {
          answer: doc.data().answer,
          day: doc.data().day
        }
      })
      console.log("Answers :", answers);
      const downloadedChallenges = [];
      snapshot.forEach(doc => {
        let answer = answers.find(a => a.day === Number(doc.data().day));
        console.log("Found answer: ", answer);
        if (answer) delete answer.day;

        console.log("answer after delete:", answer);
        const newObject = { id: doc.id, ...doc.data(), ...answer };
        console.log("newObject:", newObject);
        downloadedChallenges.push(newObject);
      });

      this.props.onAddChallenges(downloadedChallenges);

      console.log("challenges: ", downloadedChallenges);
    } catch (error) {
      console.error("Could not complete call: ", error);
      alert(
        `Error! Could not complete call: ${error}, neem een screenshot en contacteer Arno`
      );
    }
  };
}

export default AllChallenges;
