import React from 'react';
import lock from "../images/colored_lock.png"

const LockOverlay = ({ index }) => {
    return (
        <>
            <div className="dark">
                <div className="lock-text">
                    <h1>DAY {index + 1}</h1>
                    <img src={lock} alt="lock" />
                </div>
                <div className="clear"></div>
                <p>Unlock by completing the previous challenge!</p>
            </div>
            <div className="divider"></div>
        </>
    );
}
export default LockOverlay;