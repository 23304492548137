import { observable } from 'mobx';
import CookiesManager from "../../util/cookies_manager";

const cookieStore = observable({
    cookiesManager: new CookiesManager(),
    cookie: undefined,
});

cookieStore.getCookie = function () {
    if (!this.cookie) {
        let cookie = this.cookiesManager.getVisitorCookie();
        if (!cookie) this.cookiesManager.createVisitorCookie();
        this.cookie = cookie;
    }
    return this.cookie;
}

cookieStore.toNextChallenge = function () {
    if (!this.cookie) this.getCookie();
    this.cookie.answered.push(Number(this.cookie.challengeNumber));
    this.cookie.challengeNumber++;
    this.cookiesManager.updateVisitorCookie(this.cookie);
}

cookieStore.setDayAnswered = function (day) {
    if (!this.cookie) this.getCookie();
    this.cookie.answered.push(Number(day));
    this.cookiesManager.updateVisitorCookie(this.cookie);
}

cookieStore.updateChallengeNumber = function (number) {
    this.cookie.challengeNumber = Number(number);
    this.cookiesManager.updateVisitorCookie(this.cookie);
}

cookieStore.isDayAnswered = function (day) {
    if (!this.cookie) this.getCookie();
    if (this.cookie.answered) {
        const answered = this.cookie.answered.find(answeredDay => answeredDay === Number(day));
        return answered !== undefined;
    }
    return false;
}

export default cookieStore