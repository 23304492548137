import React from "react";

import history from "../util/history";
import MyLoader from "../admin/my_loader";
import Challenge from "./challenge";

import { SHARE_MESSAGE, SHARE_URL } from "../util/constants";
import ShareButtons from "./share_buttons";
import { Responsive } from "semantic-ui-react";
import challengesStore from './store/challenges_store';
import cookieStore from "./store/cookie_store";

import { observer } from 'mobx-react';
import 'semantic-ui-css/semantic.min.css'
import "./game.css";

const Game = observer(class Game extends React.Component {

  constructor(props) {
    super(props);
    this.cookie = undefined;
  }

  componentDidMount() {
    challengesStore.loadAllChallenges();
    this.cookie = cookieStore.getCookie();
    if (!this.cookie) history.push("/");
    challengesStore.setCurrentDay(this.cookie.challengeNumber)
  }

  render() {
    if (challengesStore.challenges.length === 0) return <MyLoader />;
    return (
      <>
        <div onClick={() => history.push("/")} id="homelink">
          Home
      </div>
        {this._renderChallenges(challengesStore.challenges)}
        <div id="share-buttons">
          <h4>Need help? Share with your friends!</h4>
          {this._renderResponsiveShareButtons()}
        </div>
      </>);
  }

  _renderChallenges = () => {
    let start = 0 + (challengesStore.currentWeek * 7);
    let end = 6 + (challengesStore.currentWeek * 7);
    const challenges = [];
    for (let i = start; i <= end; i++) {
      if (i + 1 <= 30) challenges.push(challengesStore.getChallengeByDay(i + 1));
    }

    return challenges.map((challenge, index) =>
      <>
        <Challenge
          key={`${challenge.day}-${index}`}
          challenge={challenge}
          index={index + (challengesStore.currentWeek * 7)}
        />
      </>
    );
  };

  _renderResponsiveShareButtons = () => {
    const title = "Lockdown with Larry";
    const hashtags = ["#lockdownwithlarry #30days30challenges"];
    return (
      <>
        <Responsive as={ShareButtons}
          {...Responsive.onlyMobile}
          size={25}
          round={true}
          url={SHARE_URL}
          message={SHARE_MESSAGE}
          title={title}
          hashtags={hashtags}>
        </Responsive>
        <Responsive as={ShareButtons} minWidth={768} maxWidth={991}
          size={40}
          round={true}
          url={SHARE_URL}
          message={SHARE_MESSAGE}
          title={title}
          hashtags={hashtags}>
        </Responsive>
        <Responsive as={ShareButtons} minWidth={992}
          size={40}
          round={true}
          url={SHARE_URL}
          message={SHARE_MESSAGE}
          title={title}
          hashtags={hashtags}>
        </Responsive>
      </>
    );
  }

});

export default Game;
