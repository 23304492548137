import React from "react";
import remote from "../images/remote_q60.webp";
import Larry from "../images/larry_q50.webp";
import LockDown from "../images/lock_down.png";
import WithLarry from "../images/with_larry.png";
import history from "../util/history";
import CookiesManager from "../util/cookies_manager";
import { analytics } from "../util/firebase_functions";
import { EVENT_NEW_COOKIE_CREATED } from "../util/constants";
import "./start.css";

class Start extends React.Component {
  constructor(props) {
    super(props);
    const cookieManager = new CookiesManager();
    this.cookie = cookieManager.getVisitorCookie();
  }

  render() {
    return (
      <>
        <div className="larry-main">
          <img key="plan_1" alt="lock_down_plan" id="plan_1" src={remote}></img>
          <img key="plan_2" alt="lock_down_plan" id="plan_2" src={remote}></img>
          <img key="plan_3" alt="lock_down_plan" id="plan_3" src={remote}></img>
          <div className="images">
            <img alt="lock_down" src={LockDown}></img>
            <img alt="with_larry" src={WithLarry}></img>
            <img alt="larry" id="larry" src={Larry}></img>
          </div>
          <div className="intro-text">
            <p>
              30 days, 30 challenges
              <br />
              One pet hedgehog
            </p>
            <div id="half-way-banner">
              <p id="first">A VIRTUAL ADVENTURE</p>
              <p id="second">FOR THOSE STUCK AT HOME</p>
            </div>
            <p>
              No vacations, no parties, no friends
              <br />
              Being stuck at home is crazy boring.
              <br />
              But thanks to Larry, it can be one hell of an adventure too.
            </p>
            <p>
              Craving a chance to go outside?
              <br />
              <b>Take your mind for a spin</b> and solve
              <br />
              one challenge each day. It won't be
              <br />
              easy, but it'll be worth it.
            </p>
            <p>
              Do it for you.
              <br />
              Do it for Larry the Hedgehog.
            </p>
          </div>

          <div id="break-free" onClick={this._onStartGame}>
            {this.cookie ? "CONTINUE!" : "BREAK FREE!"}
          </div>
        </div>
      </>
    );
  }

  _onStartGame = () => {
    const cookiesManager = new CookiesManager();
    const visitorCookie = cookiesManager.getVisitorCookie();
    if (!visitorCookie) {
      analytics.logEvent(EVENT_NEW_COOKIE_CREATED, {
        date: new Date(),
      });
      cookiesManager.createVisitorCookie();
    }

    history.push("/game");
  };
}

export default Start;
