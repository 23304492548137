import React from 'react';
import { Input, Popup } from 'semantic-ui-react';

class AnswerInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            answer: undefined
        }
    }

    render() {
        const { onSubmit, onTipClicked } = this.props;
        return (
            <div id="answer">
                <Input
                    placeholder="Answer"
                    onKeyPress={this._handleKeyPress}
                    onChange={(_, { value }) => {
                        this.setState({ answer: value });
                    }}
                />
                <Popup size="small" trigger={
                    <a id="tip" onClick={onTipClicked}
                        href="https://www.buymeacoffee.com/mom/shop" target="_blank" rel="noopener noreferrer">Hint?</a>
                }
                >
                    <Popup.Header>Feeling stuck?</Popup.Header>
                    <Popup.Content>
                        Visit my mom’s coffee shop. She might be able to help you out.
                    </Popup.Content>
                </Popup>
                <div id="try" onClick={
                    () => onSubmit(this.state.answer)
                }>Try!</div>
            </div>
        );
    }

    _handleKeyPress = (event) => {
        if (event.key === "Enter") this.props.onSubmit(this.state.answer);
    };
}

export default AnswerInput;