import React from "react";
import { Loader, Dimmer } from "semantic-ui-react";

const MyLoader = () => (
  <Dimmer active>
    <Loader content="Loading" />
  </Dimmer>
);

export default MyLoader;
