import React from "react";
import { TextArea, Form } from "semantic-ui-react";
import ImageUploader from "react-images-upload";
import AnswerFormatter from "../util/answer_formatter";

class ChallengeForm extends React.Component {
  constructor(props) {
    super(props);
    const challenge = this.props.challenge ? this.props.challenge : undefined;
    this.state = {
      pictures: [],
      title: challenge ? (challenge.title ? challenge.title : "") : "",
      subtitle: challenge ? (challenge.subtitle ? challenge.subtitle : "") : "",
      description: challenge
        ? challenge.description
          ? challenge.description
          : ""
        : "",
      day: challenge ? (challenge.day ? challenge.day : 0) : 0,
      answer: challenge ? (challenge.answer ? challenge.answer : "") : "",
      tip_1: challenge ? (challenge.tip_1 ? challenge.tip_1 : "") : "",
      tip_2: challenge ? (challenge.tip_2 ? challenge.tip_2 : "") : "",
      tip_3: challenge ? (challenge.tip_3 ? challenge.tip_3 : "") : ""
    };
  }

  render() {
    const {
      title,
      subtitle,
      description,
      answer,
      tip_1,
      tip_2,
      tip_3,
      day
    } = this.state;

    return (
      <>
        <Form onSubmit={this._handleSubmit}>
          <Form.Input
            required
            name="day"
            label="Dag (Cijfer)"
            value={day}
            placeholder="Dag"
            onChange={this._handleChange}
          />
          <Form.Input
            required
            name="title"
            label="Titel"
            value={title}
            placeholder="Titel"
            onChange={this._handleChange}
          />
          <Form.Input
            required
            name="subtitle"
            label="Subtitel"
            value={subtitle}
            placeholder="Subtitel"
            onChange={this._handleChange}
          />
          <Form.Field>
            <label>Afbeelding</label>
            <ImageUploader
              withIcon={true}
              withPreview={true}
              singleImage={true}
              buttonText="Kies een afbeelding"
              onChange={this._onDrop}
              imgExtension={[".jpg", ".gif", "jpeg", ".png"]}
              maxFileSize={5242880}
            />
          </Form.Field>
          <Form.Field required>
            <label>Beschrijving</label>
            <TextArea
              required
              name="description"
              placeholder="Beschrijving"
              value={description}
              onChange={this._handleChange}
            ></TextArea>
          </Form.Field>
          <Form.Input
            required
            name="answer"
            label="Antwoord"
            value={answer}
            placeholder="Antwoord"
            onChange={this._handleChange}
          />
          <Form.Input
            name="tip_1"
            label="Tip 1"
            value={tip_1}
            placeholder="Tip 1"
            onChange={this._handleChange}
          />
          <Form.Input
            name="tip_2"
            label="Tip 2"
            value={tip_2}
            placeholder="Tip 2"
            onChange={this._handleChange}
          />
          <Form.Input
            name="tip_3"
            label="Tip 3"
            value={tip_3}
            placeholder="Tip 3"
            onChange={this._handleChange}
          />
          <Form.Button id="submit-button" type="submit">
            {this.props.buttonText}
          </Form.Button>
        </Form>
      </>
    );
  }
  _onDrop = async picture => {
    await this.setState({
      pictures: this.state.pictures.concat(picture)
    });
  };

  _handleChange = (e, { name, value }) => this.setState({ [name]: value });

  _formatAnswer = async () => {
    const formattedAnswer = new AnswerFormatter().formatAnswer(
      this.state.answer
    );
    await this.setState({
      answer: formattedAnswer
    });
  };

  _handleSubmit = async () => {
    try {
      await this._formatAnswer();

      const obj = {
        title: this.state.title,
        subtitle: this.state.subtitle,
        description: this.state.description,
        day: Number(this.state.day),
        answer: this.state.answer,
        tip_1: this.state.tip_1,
        tip_2: this.state.tip_2,
        tip_3: this.state.tip_3
      };

      let challengeObject = obj;
      if (this.props.challenge)
        challengeObject = { ...obj, id: this.props.challenge.id };

      this.props.onSubmit(challengeObject, this.state.pictures[0]);
    } catch (error) {
      console.error("Error while adding to firestore: ", error);
      alert(
        `Error! Error while adding to firestore: ${error}. Neem een screenshot en stuur door naar Arno!`
      );
    }
  };
}

export default ChallengeForm;
