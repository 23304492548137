import React from "react";
import AdminRoutes from "./routes/admin_routes";
import GameRoutes from "./routes/game_routes";
import "./app.css";

function LockdownWithLarryApp() {
  return (
    <>
      <AdminRoutes />
      <GameRoutes />
    </>
  );
}

export default LockdownWithLarryApp;
