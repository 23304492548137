import React from "react";
import { Header, Modal } from "semantic-ui-react";
import ShareButtons from '../share_buttons';


class ShareModal extends React.Component {
  render() {
    return (
      <Modal
        size="mini"
        centered={true}
        onClose={this.props.onClose}
        open={this.props.open}
      >
        <Header>Care to SHARE?</Header>
        <Modal.Content centered="true">
          <p>
            Thanks for sharing! When you're done sharing, enter 'share' as the
            answer to continue to the next challenge!
          </p>
          <ShareButtons
            url={this.props.url}
            message={this.props.message}
            title={this.props.title}
            hashtags={this.props.hashtags}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default ShareModal;
