import React from "react";
import { Header, Modal } from "semantic-ui-react";
class WinFailModal extends React.Component {
  render() {
    return (
      <Modal
        basic
        centered={true}
        size="small"
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={this.props.onClose}
        open={this.props.open}
      >
        <Header icon={this.props.iconName} content={this.props.title} />
        <Modal.Content>{this.props.content}</Modal.Content>
        <Modal.Actions>{this.props.actions}</Modal.Actions>
      </Modal>
    );
  }
}

export default WinFailModal;
