import app from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

/*const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};*/

const firebaseConfig = {
  apiKey: "AIzaSyCZvB1L0R3FWgxE0ph8rIAhnZsgOeJ0F4c",
  authDomain: "lockdown-with-larry.firebaseapp.com",
  databaseURL: "https://lockdown-with-larry.firebaseio.com",
  projectId: "lockdown-with-larry",
  storageBucket: "lockdown-with-larry.appspot.com",
  messagingSenderId: "116800413234",
  appId: "1:116800413234:web:4ea302ee6fe71486ed662b",
  measurementId: "G-W47LL2QP91"
};

export const myFirebaseApp = app.initializeApp(firebaseConfig);
export const auth = myFirebaseApp.auth();
export const firestore = myFirebaseApp.firestore();
export const analytics = myFirebaseApp.analytics();
export const storage = myFirebaseApp.storage();
