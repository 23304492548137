import React from "react";
import { Image } from "semantic-ui-react";
import Larry from "../images/larry.png";
import Completed from "./completed_all";

class EndPage extends React.Component {

  constructor(props) {
    super(props);
    console.log("this.props.location", this.props.location);
    console.log("search:", this.props.location.search);
    const parts = this.props.location.search.split(/[?&]/);
    console.log('parts:', parts)
    const passedByQuery = {};
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === "") continue;
      const part = parts[i];
      const key_params = part.split("=");
      passedByQuery[key_params[0]] = key_params[1];
    }
    console.log("passedByQuery:", passedByQuery);
    console.log("param name:", passedByQuery['name']);
    this.name = passedByQuery['name'];
  }

  render() {
    return (
      <>
        <div id="end">
          <Image alt="larry the hedgehog" src={Larry} />
          <h1>Congratulations!</h1>
          <Completed name={this.name} />
        </div>
      </>
    );
  }
}

export default EndPage;
