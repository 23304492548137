import React from "react";
import { Route } from "react-router-dom";

import Game from "../game/days";
import Start from "../game/index";
import EndPage from "../game/end";

const AdminRoutes = () => {
  return (
    <>
      <Route path="/" exact component={Start} />
      <Route path="/game" exact component={Game} />
      <Route path="/game/end" exact component={EndPage} />
    </>
  );
};

export default AdminRoutes;
