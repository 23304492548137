import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookIcon,
    WhatsappIcon,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";

class ShareButtons extends React.Component {
    constructor(props) {
        super(props);
        this.props.size ? this.size = this.props.size : this.size = 64;
        this.props.round ? this.round = this.props.round : this.round = false;
    }

    render() {
        return (
            <>
                <FacebookShareButton
                    className="share-button"
                    url={this.props.url}
                    quote={this.props.message}
                    hashtag={`${this.props.hashtags[0]} ${this.props.hashtags[1]}`}
                >
                    <FacebookIcon
                        size={this.size}
                        round={this.round}
                    />
                </FacebookShareButton>
                <WhatsappShareButton
                    title={this.props.message}
                    url={this.props.url}
                    className="share-button"
                >
                    <WhatsappIcon
                        size={this.size}
                        round={this.round}
                    />
                </WhatsappShareButton>
                <LinkedinShareButton
                    title={this.props.title}
                    summary={this.props.message}
                    url={this.props.url}
                    source={this.props.url}
                    className="share-button"
                >
                    <LinkedinIcon
                        size={this.size}
                        round={this.round}
                    />
                </LinkedinShareButton>
                <TwitterShareButton
                    url={this.props.url}
                    title={this.props.message}
                    hashtags={this.props.hashtags}
                    className="share-button"
                >
                    <TwitterIcon
                        size={this.size}
                        round={this.round}
                    />
                </TwitterShareButton>
            </>
        );
    }
}

export default ShareButtons;