import React from "react";
import CookiesManager from "../util/cookies_manager";

import { DEV_MODE } from "../util/constants";
import { Menu } from "semantic-ui-react";
import AddChallenge from "./admin_add_challenge";
import AllChallenges from "./admin_all_challenges";
import history from "../util/history";

import "./admin.css";
import EditChallenge from "./admin_edit_challenge";
import MyLoader from "./my_loader";
const MENU_ALL = "all_challenges";
const MENU_ADD = "add_challanges";
const MENU_EDIT = "edit_challenge";
const MENU_LOGOUT = "logout";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.cookiesManager = new CookiesManager();

    this.state = {
      cookie: undefined,
      activeItem: MENU_ALL,
      allChallenges: [],
      editingChallenge: undefined
    };
  }

  componentDidMount() {
    this._checkCookie();
  }

  render() {
    if (this.state.cookie === undefined) return <MyLoader />;
    const { activeItem, cookie } = this.state;
    return (
      <>
        {this._renderMenu(activeItem, cookie)}
        <div className="challenges-content">{this._renderContentPage()}</div>
      </>
    );
  }

  _renderContentPage = () => {
    switch (this.state.activeItem) {
      case MENU_ALL:
        return (
          <AllChallenges
            challenges={this.state.allChallenges}
            onAddChallenges={this.onChallengesAdded}
            onDeleteChallenge={this.onChallengeDeleted}
            onEditChallenge={this.onChallengeEdit}
          />
        );
      case MENU_ADD:
        return <AddChallenge onCreated={this.onChallengeCreated} />;
      case MENU_EDIT:
        return (
          <EditChallenge
            onEdited={this.onChallengeEditCompleted}
            challenge={this.state.editingChallenge}
          />
        );
      default:
        return (
          <AllChallenges
            challenges={this.state.allChallenges}
            onAddChallenges={this.onChallengesAdded}
            onDeleteChallenge={this.onChallengeDeleted}
            onEditChallenge={this.onChallengeEdited}
          />
        );
    }
  };

  _checkCookie = async () => {
    let cookie = this.cookiesManager.getUserCookie();

    //Override in dev mode
    if (DEV_MODE) cookie = this._initDevCookie();

    await this.setState({
      cookie
    });
    if (this.state.cookie === undefined) history.push("/admin/login");
  };

  onChallengeEdit = challenge => {
    this.setState({
      activeItem: MENU_EDIT,
      editingChallenge: challenge
    });
  };

  onChallengeEditCompleted = updated => {
    const allChallenges = this.state.allChallenges;
    const original = allChallenges.find(c => c.id === updated.id);
    const day = updated.day === original.day ? original.day : updated.day;
    const title =
      original.title === updated.title ? original.title : updated.title;
    const subtitle =
      original.subtitle === updated.subtitle
        ? original.subtitle
        : updated.subtitle;
    const description =
      original.description === updated.description
        ? original.description
        : updated.description;
    const answer =
      original.answer === updated.answer ? original.answer : updated.answer;
    const tip_1 =
      original.tip_1 === updated.tip_1 ? original.tip_1 : updated.tip_1;
    const tip_2 =
      original.tip_2 === updated.tip_2 ? original.tip_2 : updated.tip_2;
    const tip_3 =
      original.tip_3 === updated.tip_3 ? original.tip_3 : updated.tip_3;
    const picture = updated.picture
      ? original.picture === updated.picture
        ? original.picture
        : updated.picture
      : original.picture;

    const updatedObject = {
      id: original.id,
      day,
      title,
      subtitle,
      picture,
      description,
      answer,
      tip_1,
      tip_2,
      tip_3
    };

    const originalIndex = allChallenges.findIndex(c => c.id === updated.id);
    allChallenges.splice(originalIndex, 1);
    allChallenges.push(updatedObject);

    this.setState({
      activeItem: MENU_ALL,
      editingChallenge: undefined,
      allChallenges: allChallenges
    });
  };

  onChallengeCreated = newChallenge => {
    const updatedChallenges = this.state.allChallenges;
    updatedChallenges.push(newChallenge);
    this.setState({
      allChallenges: updatedChallenges,
      activeItem: MENU_ALL
    });
  };

  onChallengesAdded = newChallenges => {
    const originalChallenges = this.state.allChallenges;
    const updatedChallenges = originalChallenges.concat(newChallenges);
    this.setState({
      allChallenges: updatedChallenges
    });
  };

  onChallengeDeleted = challenge => {
    const currentChallenges = this.state.allChallenges;
    const deletedIndex = currentChallenges.findIndex(
      c => challenge.id === c.id
    );
    currentChallenges.splice(deletedIndex, 1);
    this.setState({
      allChallenges: currentChallenges
    });
  };

  _changeMenu = (event, { name }) => {
    this.setState({
      activeItem: name
    });
  };

  _renderMenu = (activeItem, cookie) => {
    return (
      <Menu>
        <Menu.Item
          name={MENU_ALL}
          active={activeItem === MENU_ALL}
          onClick={this._changeMenu}
        >
          Alle Challenges
        </Menu.Item>
        <Menu.Item
          name={MENU_ADD}
          active={activeItem === MENU_ADD}
          onClick={this._changeMenu}
        >
          Challenge Toevoegen
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            {cookie ? (
              cookie.email ? (
                <b>{cookie.email}</b>
              ) : (
                <b>"No e-mail address. Weird."</b>
              )
            ) : (
              "loading"
            )}
          </Menu.Item>
          <Menu.Item
            name={MENU_LOGOUT}
            active={activeItem === MENU_LOGOUT}
            onClick={this._logOut}
          >
            Uitloggen
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  };

  _logOut = () => {
    this.cookiesManager.deleteUserCookie();
    history.push("/admin/login");
    this.setState({ cookie: undefined });
  };

  _initDevCookie = () => {
    return {
      creationTime: new Date().toISOString(),
      email: "arnosoontjens@outlook.com",
      lastSignInTime: new Date().toISOString(),
      refreshToken:
        "AEu4IL2UV41JMaCGveM0AeR1UzJWBLP5N0tKdvdtZ7cZRqTPiF9X2O899DmqJ9WIGeA-MOETZ9PCacaDRCxvHqIQ2X5IpKUZImM_Hu1ER2TmxGB0ArFI44fvvm8V5-lJCOek-LNQPOM4HK0ZAwZyvHVsfPm9cFtRM3Tw4JrKG-Ftf3g3yLMAgnVDitrp3C0JiZlBuPmVj_m2U-vL7GHUauWfxNjyMUXwjymOUG4EpDi_uq7q_6n6IG8",
      token:
        "eyJhbGciOiJSUzI1NiIsImtpZCI6IjFmODhiODE0MjljYzQ1MWEzMzVjMmY1Y2RiM2RmYjM0ZWIzYmJjN2YiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbG9ja2Rvd24td2l0aC1sYXJyeSIsImF1ZCI6ImxvY2tkb3duLXdpdGgtbGFycnkiLCJhdXRoX3RpbWUiOjE1ODUyNTY1NDMsInVzZXJfaWQiOiJ6eHUwQWF6UmUyWERMUFM5YVM1eVF0VFc1SUwyIiwic3ViIjoienh1MEFhelJlMlhETFBTOWFTNXlRdFRXNUlMMiIsImlhdCI6MTU4NTI1NjU0MywiZXhwIjoxNTg1MjYwMTQzLCJlbWFpbCI6ImFybm9zb29udGplbnNAb3V0bG9vay5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYXJub3Nvb250amVuc0BvdXRsb29rLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.j6xY6f68l7MuW8Me3h-V2uv1uP_IrBhY7NR9Ayxu_ax5lzMFSs_B4OoOQ-Z4proI3pZjiaISYy-xGIAev256Hz03vOJSI_j_59itXGsncROGssMCWdR52zZF7ub-OOvt7jxHiJ1Tb-xMnZDRl8AX4GCrZktV2bz1Y0ZIoWvuOy9dVb38rJHwUZgX7quh4MjAGhx0kvWBPuV_8eeUlW4slItJpMWNb4GBLTn52fQ5-CeXWBuYKSgZrJXfnqWWsNvfMRwPErvuLfMtjFCHzDIsJQYAXgLV_hgfsM9ef6EVFrH478Kcwf9ExunSENrRX_fLsMDD0198pxGtaTBD1hTRuQ",
      uid: "zxu0AazRe2XDLPS9aS5yQtTW5IL2"
    };
  };
}

export default Admin;
