import React from "react";
import { Grid, Input, Container, Button } from "semantic-ui-react";

import { auth } from "../util/firebase_functions";
import history from "../util/history";
import CookiesManager from "../util/cookies_manager";
import larry from "../images/larry.png";
import { DEV_MODE } from "../util/constants";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  componentDidMount() {
    if (DEV_MODE) console.info("Running in dev mode!");
    this.cookiesManager = new CookiesManager();
  }

  render() {
    return (
      <>
        <Container fluid className="login">
          <Grid padded centered>
            <Grid.Row>
              <img id="larry" alt="larry" src={larry} />
            </Grid.Row>
            <Grid.Row>
              <h2>Login voor admins</h2>
            </Grid.Row>
            <Grid.Row>
              <Input
                iconPosition="left"
                icon="at"
                placeholder="E-mail adres"
                onChange={(_, data) =>
                  this.setState({
                    username: data.value
                  })
                }
              />
            </Grid.Row>
            <Grid.Row>
              <Input
                icon="lock"
                iconPosition="left"
                placeholder="Wachtwoord"
                type="password"
                onChange={(_, data) =>
                  this.setState({
                    password: data.value
                  })
                }
              />
            </Grid.Row>
            <Grid.Row>
              <Button onClick={this._login}>Login</Button>
            </Grid.Row>
          </Grid>
        </Container>
      </>
    );
  }

  _login = async () => {
    try {
      if (!DEV_MODE) {
        const result = await auth.signInWithEmailAndPassword(
          this.state.username,
          this.state.password
        );
        console.log("Result:", result);
        //TODO: store in session token;
        const additionalInfo = result.additionalUserInfo;
        const cred = result.credential;
        const operationType = result.operationType;

        this.cookiesManager.createUserCookie(result.user);
      } else {
        console.info(
          "Running in dev mode, redirecting without actual Firebase auth call."
        );
      }

      history.push("/admin");
    } catch (error) {
      // TODO: handle errors
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(`Error ${errorCode}: ${errorMessage}`);
    }
  };
}

export default Login;
