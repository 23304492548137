import React from "react";
import { Route } from "react-router-dom";
import Login from "../admin/login";
import Admin from "../admin/admin";
import EditChallenge from "../admin/admin_edit_challenge";

const AdminRoutes = () => {
  return (
    <>
      <Route path="/admin" exact component={Admin} />
      <Route path="/admin/login" exact component={Login} />
      <Route path="/admin/challenge/edit" exact component={EditChallenge} />
    </>
  );
};

export default AdminRoutes;
