import React from "react";
import ChallengeForm from "./challenge_form";
import { firestore, storage } from "../util/firebase_functions";
import { FS_COLLECTION_CHALLENGES } from "../util/constants";
import history from "../util/history";

class EditChallenge extends React.Component {
  render() {
    console.log("Challenge:", this.props.challenge);
    return (
      <div className="challenges-content">
        <h2>Challenge Bewerken</h2>
        <ChallengeForm
          buttonText="Aanpassen!"
          challenge={this.props.challenge}
          onSubmit={this._handleSubmit}
        />
      </div>
    );
  }

  _handleSubmit = async (updatedChallenge, pictureFile) => {
    try {
      console.log("Updated Challenge: ", updatedChallenge);
      console.log("Picture file:", pictureFile);

      let pictureRef;
      if (pictureFile) pictureRef = await this._uploadImgToStorage(pictureFile);

      let newObject = updatedChallenge;
      //TODO: if there is a new picture that will replace the old one, we should remove the old one from storage
      if (pictureRef) newObject = { ...updatedChallenge, picture: pictureRef };

      await firestore
        .collection(FS_COLLECTION_CHALLENGES)
        .doc(updatedChallenge.id)
        .update(newObject);

      this.props.onEdited(newObject);
      history.push("/admin");
    } catch (error) {
      console.error("Error while adding to firestore: ", error);
      alert(
        `Error! Error while adding to firestore: ${error}. Neem een screenshot en stuur door naar Arno!`
      );
    }
  };

  _uploadImgToStorage = async file => {
    try {
      const storageRef = storage.ref();
      let newImageRef = storageRef.child(file.name);
      const snapshot = await newImageRef.put(file);
      return `gs://${snapshot.ref.location.bucket}/${snapshot.ref.location.path}`;
    } catch (error) {
      console.error("Could not upload image: ", error);
      alert(
        `Error! Could not upload image: ${error}. Neem een screenshot en stuur door naar Arno!`
      );
    }
  };
}

export default EditChallenge;
