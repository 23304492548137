import React from 'react';
import { Input, Button, Message } from 'semantic-ui-react';
import { firestore } from "../util/firebase_functions";

class Completed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name ? this.props.name : "",
            email: "",
            finished: false,
            error: undefined
        }
    }

    render() {
        return (
            <>
                <h2>
                    You must be some kind of genius! You've completed
                    all of our challenges!
                </h2>
                <p>
                    Leave your name and e-mail so we can add you to our leaderboard and find out how well you did! (We promise we won't send you anything else!)
                </p>
                {this.state.finished ? (<h2>Thank you for playing!</h2>) :
                    <>
                        <Message negative hidden={this.state.error === undefined}>
                            <Message.Header>Something went wrong!</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                        <Input required
                            label="Name"
                            onChange={(_, data) => {
                                this.setState({ name: data.value });
                            }}
                            value={this.state.name}>

                        </Input>
                        <br />
                        <br />
                        <Input
                            label="E-mail"
                            onChange={(_, data) => {
                                this.setState({ email: data.value });
                            }}
                            value={this.state.email}>

                        </Input>
                        <br />
                        <br />
                        <Button id="submit" onClick={() => this.addNameAndEmail()}>Submit!</Button>
                    </>
                }
            </>
        )
    }

    async addNameAndEmail() {
        if (this.state.name.length >= 2 && this.state.email.length >= 2) {
            const finisher = await firestore.collection("finishers").add({
                name: this.state.name,
                email: this.state.email,
                timestamp: new Date().toUTCString()
            });
            if (finisher) {
                this.setState({
                    finished: true
                });
            }
        } else {
            this.setState({
                error: "Please provide your name and an e-mail address!"
            })
        }

    }
}

export default Completed;