import React from "react";
import { Image, Button, Icon } from "semantic-ui-react";
import { firestore, storage, analytics } from "../util/firebase_functions";
import AnswerFormatter from "../util/answer_formatter";
import ReactHtmlParser from "react-html-parser";
import { observer } from 'mobx-react';
import {
  FS_COLLECTION_ANSWERS,
  EVENT_QUESTION_ANSWERED,
  EVENT_QUESTION_WRONG,
  EVENT_QUESTION_RIGHT,
  EVENT_SHARE_BUTTON_CLICKED,
  EVENT_TIP_BUTTON_CLICKED,
  SHARE_MESSAGE,
  SHARE_URL
} from "../util/constants";
import MyLoader from "../admin/my_loader";

import ShareModal from "./modals/share_modal";
import WinFailModal from "./modals/win_fail_modal";
import cookieStore from './store/cookie_store';
import challengesStore from './store/challenges_store';
import AnswerInput from "./answer_input";
import LockOverlay from "./lock_overlay";
import history from "./../util/history";

const Challenge = observer(class Challenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isRightAnswer: false,
      image: undefined,
      answer: undefined
    };
    this.currentChallenge = undefined;
  }

  componentDidMount() {
    this.currentChallenge = challengesStore.getCurrentChallenge();
    if (this.props.challenge) this._fetchImage(this.props.challenge.picture);
  }

  render() {
    const { showModal, isRightAnswer } = this.state;
    const { challenge, index } = this.props;

    if (!challenge) return <MyLoader />;

    const { day, title, subtitle } = challenge;
    return (
      <>
        {index > Number(challengesStore.getCurrentChallenge().day) - 1 ? (
          <LockOverlay index={index} />
        ) : (
            <div className="challenge">
              <h1>DAY {day ? day : "Loading"}</h1>
              <h2>{title ? title : "Loading"}</h2>
              <div id="challenge-explanation">
                <div id="description">{this._getDescription(this.currentChallenge)}</div>
              </div>
              {this.state.image ? (
                <Image
                  alt="challenge image"
                  style={day === "1" ? { width: "50%" } : {}}
                  src={this.state.image}
                />
              ) : null}

              <h3 id="subtitle">{subtitle ? subtitle : "Loading"}</h3>

              {day === "1"
                ? (
                  <p><b>Pro-tip for starters:</b>It’s a riddle, not a film quiz, so no guessing is required. All you need to know to solve it can be found via hints in the text! Might require you to do some Googling too!</p>
                ) : null}

              {this._renderAnswerPart(day)}
              <div className="divider"></div>
            </div>
          )}
        {showModal
          ? isRightAnswer
            ? this._renderWinnerModal()
            : this._renderLoserModal()
          : null}
      </>
    );
  }

  _renderAnswerPart = (day) => {
    if (cookieStore.isDayAnswered(Number(day))) {
      return <div id="correct">Well done!</div>
    } else {
      return <AnswerInput onSubmit={
        (answer) => this._submitAnswer(answer)}
        onTipClicked={
          () => analytics.logEvent(EVENT_TIP_BUTTON_CLICKED, {
            challenge: day
          })}
      />;
    }
  }

  _submitAnswer = async (answer) => {
    this._logAnswerEvent(EVENT_QUESTION_ANSWERED);

    const { day } = this.props.challenge;
    if (answer && answer.length >= 1) {
      let isRightAnswer = false;
      //If this is the last challenge, every answer is accepted.
      if (day === "30") {
        isRightAnswer = true;
      } else {
        const formatted = new AnswerFormatter().formatAnswer(answer);
        console.log(`Looking for ${formatted} on day ${day}`);
        const found = await firestore
          .collection(FS_COLLECTION_ANSWERS)
          .where("day", "==", Number(day))
          .where("answer", "==", formatted)
          .limit(1)
          .get();
        if (found.empty) {
          this._logAnswerEvent(EVENT_QUESTION_WRONG, formatted);
        } else {
          this._logAnswerEvent(EVENT_QUESTION_RIGHT, formatted);
          isRightAnswer = true;
        }
      }

      this.setState({
        answer,
        showModal: true,
        isRightAnswer
      })
    }
  };

  _logAnswerEvent = (event, answer) => {
    if (answer) {
      analytics.logEvent(event, {
        challenge: this.currentChallenge.day,
        answer,
      });
    } else {
      analytics.logEvent(event, {
        challenge: this.currentChallenge.day,
      });
    }
  }

  _getDescription = () => this.props.challenge.description ?
    ReactHtmlParser(this.props.challenge.description) : "Loading";

  _fetchImage = async (picture) => {
    let image;
    try {
      image = await storage.refFromURL(picture).getDownloadURL();
    } catch (error) {
      image = undefined;
    }
    this.setState({ image });
  };

  _onShare = () => {
    analytics.logEvent(EVENT_SHARE_BUTTON_CLICKED);
    this.setState({
      showShareModal: true,
    });
  };

  closeShareModal = () => this.setState({ showShareModal: false });

  _renderSharePopUp = () => {
    return (
      <ShareModal
        url={SHARE_URL}
        message={SHARE_MESSAGE}
        title="Lockdown with Larry"
        hashtags={["#lockdownwithlarry #30days30challenges"]}
        onClose={this.closeShareModal}
        open={this.state.showShareModal}
      />
    );
  };

  close = () => this.setState({ showModal: false });

  _renderWinnerModal = () => {
    return (
      <WinFailModal
        open={this.state.showModal}
        onClose={this.close}
        iconName="gift"
        title="Clever, are you?"
        content={<p>Congratulations, you cracked it! On to the next one.</p>}
        actions={
          <Button color="green" inverted onClick={() => {
            if (Number(this.currentChallenge.day) === 30) {
              console.log("Should go to end page here!");
              history.push(`game/end?name=${this.state.answer}`);
            } else {
              cookieStore.toNextChallenge(this.currentChallenge.day);
              challengesStore.toNextChallenge();
            }

            this.close();
          }
          }>
            <Icon name="forward" />
            Go there now!
          </Button>
        }
      />
    );
  };

  _renderLoserModal = () => {
    return (
      <WinFailModal
        onClose={this.close}
        open={this.state.showModal}
        iconName="exclamation"
        title="That’s not it!"
        content={
          <p>No worries, you can try again. Just try harder this time!</p>
        }
      />
    );
  };
});

export default Challenge;
