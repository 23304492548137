import { observable } from "mobx";
import { firestore } from "../../util/firebase_functions";
import { FS_COLLECTION_CHALLENGES } from "../../util/constants";

const challengesStore = observable({
    challenges: [],
    downloading: false,
    total: 0,
    currentDay: 0,
    currentWeek: 0
});

challengesStore.loadAllChallenges = async function () {
    if (this.challenges.length === 0 && this.downloading === false) {
        this.downloading = true;

        const loaded = await firestore
            .collection(FS_COLLECTION_CHALLENGES)
            .get();
        const all = [];
        loaded.docs.forEach(
            (c) => all.push(c.data())
        );
        all.sort((a, b) => a.day - b.day);
        this.challenges = all;
        this.total = this.challenges.length;
        this.downloading = false;
    }
}

challengesStore.setCurrentDay = function (day) {
    this.currentDay = day;
    this.currentWeek = Math.floor((this.currentDay - 1) / 7);
    console.log("Current day:", this.currentDay, " & current week: ", this.currentWeek);
}

challengesStore.getCurrentChallenge = function () {
    return this.challenges[this.currentDay > 0 ? this.currentDay - 1 : 0];
}

challengesStore.getChallengeByDay = function (day) {
    const dayIndex = Number(day) - 1;
    if (!this.challenges) return null;
    return this.challenges[dayIndex];
}

challengesStore.toNextChallenge = function () {
    //TODO: should we set 'game ended' or something here when challenge.day = 30 and is increased?
    this.currentDay++;
    if ((this.currentDay - 1) % 7 === 0) this.currentWeek++;
    return this.challenges[this.currentDay];
}

export default challengesStore