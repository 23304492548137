class AnswerFormatter {
  formatAnswer = answer => {
    let formatted = answer.trim();
    formatted = formatted.replace(/[/-]/g, "");
    formatted = formatted.replace(/[éè]/gi, "e");
    formatted = formatted.replace(/[ûüù]/gi, "u");
    formatted = formatted.replace(/[âä]/gi, "a");
    formatted = formatted.replace(/[ç]/gi, "c");
    formatted = formatted.replace(/[îïíì]/gi, "i");
    formatted = formatted.replace(/ /gi, "");
    formatted = formatted.toUpperCase();
    return formatted;
  };
}

export default AnswerFormatter;
